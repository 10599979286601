import { Box, Container } from '@mui/material';
import { useGetAssessmentList, useGetMe } from 'api/hooks';
import { useNavigate } from 'react-router';
import { RoutePaths } from 'routes';
import { generateRoutePath } from 'utils';
import { AssessmentListTableActions } from 'components/assessment/assessment-list-table-actions';
import { AssessmentListTable } from 'components/assessment/assessment-list-table';
import { FC, useEffect, useState } from 'react';
import { PageTitle } from 'components/shared/page-title';
import { WithSpinner } from 'components/with-spinner';
import { AssessmentFilters } from 'api/hooks/assessment/assessmentTypes';
import { AssessmentFilterSection } from './assessment-filter-section';
import { InternalExternalEdit } from './internal-external-edit/internal-external-edit';
import { RoleType } from 'api/hooks/user/userTypes';

export const AssessmentList: FC = () => {
    const [checkedAssessmentIds, setCheckedAssessmentIds] = useState<string[]>(
        []
    );
    const navigate = useNavigate();
    const [filters, setFilters] = useState<AssessmentFilters>({});
    const [sortConfig, setSortConfig] = useState<{
        column: null | string;
        order: string;
    }>({
        column: null,
        order: 'asc',
    });

    const {
        data: assessments,
        isLoading,
        query,
    } = useGetAssessmentList({
        filters,
    });

    const { data: user, isLoading: userLoading } = useGetMe({});

    const handleFilterChange = (updatedFilters: AssessmentFilters) => {
        setFilters(updatedFilters);
    };

    const handleSearch = () => {
        query.refetch();
    };

    const handleClear = () => {
        setFilters({});
    };

    useEffect(() => {
        if (Object.keys(filters).length === 0) {
            query.refetch({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const handleSort = (columnName: string) => {
        let order = 'asc';
        if (sortConfig.column === columnName && sortConfig.order === 'asc') {
            order = 'desc';
        }

        setFilters({
            ...filters,
            order_by: `${order === 'asc' ? '' : '-'}${columnName}`,
        });
        setSortConfig({ column: columnName, order });
        query.refetch();
    };

    const handleCreateNewAssessment = () => {
        navigate(generateRoutePath([RoutePaths.ASSESSMENT_LIST, '/0']));
    };

    const handleOpenClick = (id: string) => {
        const assessment = assessments.find(
            (assessment) => assessment._id === id
        );

        if (!assessment) return;

        navigate(generateRoutePath([RoutePaths.ASSESSMENT_LIST, `/${id}`]));

        // if ((assessment.status = AssessmentStatus.COMPLETED)) {
        //     navigate(
        //         generateRoutePath([
        //             RoutePaths.ASSESSMENT_LIST,
        //             `/${id}`,
        //             RoutePaths.ASSESSMENT_INFORMATION,
        //         ])
        //     );
        // }

        // navigate(
        //     generateRoutePath([
        //         RoutePaths.ASSESSMENT_LIST,
        //         `/${id}`,
        //         RoutePaths.COMPANY_INFORMATION,
        //     ])
        // );
    };

    const handleCheckAssessment = (assessmentId: string, checked: boolean) => {
        if (checked) {
            setCheckedAssessmentIds([...checkedAssessmentIds, assessmentId]);
        } else {
            const newArray = checkedAssessmentIds.filter(
                (el) => el !== assessmentId
            );
            setCheckedAssessmentIds(newArray);
        }
    };
    const isAdmin = user?.roles?.includes(RoleType.ADMIN) ?? false;
    const isPlanner = user?.roles?.includes(RoleType.PLANNER) ?? false;

    return (
        <>
            <PageTitle title='Assessment Listing (Progress Tracker)' />
            {!userLoading && (isAdmin || isPlanner) && (
                <AssessmentListTableActions
                    onCreateNewAssessment={handleCreateNewAssessment}
                />
            )}

            <AssessmentFilterSection
                filters={filters}
                onFilterChange={handleFilterChange}
                onSearch={handleSearch}
                onClear={handleClear}
            />
            <WithSpinner isLoading={isLoading || query.isRefetching}>
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '1',
                    }}
                >
                    <AssessmentListTable
                        data={assessments}
                        onOpenClick={handleOpenClick}
                        onSort={handleSort}
                        currentSortColumn={sortConfig.column}
                        currentSortOrder={sortConfig.order}
                        onCheckAssessment={handleCheckAssessment}
                    />
                </Container>
            </WithSpinner>
            <Box
                // display={'flex'}
                sx={{
                    '& > :not(style)': {
                        // m: 1,
                        // margin: 0,
                        // top: 'auto',
                        // left: 20,
                        right: 'auto',
                        bottom: 20,
                        position: 'fixed',
                    },
                }}
            >
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <InternalExternalEdit
                        assessmentIds={checkedAssessmentIds}
                    />
                </Box>
            </Box>
        </>
    );
};
