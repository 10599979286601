import { FC, memo, SyntheticEvent } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import {
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type OptionValue = {
    value: string;
    label: string;
};

type Props = {
    options: OptionValue[];
    value: string;
    onChange: (value: string) => void;
    label: string;
    disabled?: boolean;
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const CustomAutoCompleteField: FC<Props> = memo(
    ({ options, onChange, value, label, disabled = false }) => {
        const modifiedValue = stringToMultipleSelectOptions(value).map(
            (val) => JSON.parse(val) as OptionValue
        );

        const allSelected = options.length === modifiedValue.length;
        const handleSelectAll = (isSelected: boolean) => {
            if (isSelected) {
                const result = options.map((val) => JSON.stringify(val));
                onChange(multipleSelectOptionsToString(result));
            } else {
                onChange('');
            }
        };
        const handleToggleSelectAll = () => {
            handleSelectAll && handleSelectAll(!allSelected);
        };

        const handleChange = (
            event: SyntheticEvent,
            selectedOptions: OptionValue[]
        ) => {
            if (
                selectedOptions.find((option) => option.value === 'SELECT_ALL')
            ) {
                handleToggleSelectAll();
            } else {
                const result = selectedOptions.map((val) =>
                    JSON.stringify(val)
                );
                onChange(multipleSelectOptionsToString(result));
            }
        };

        return (
            <Autocomplete
                multiple
                fullWidth
                limitTags={2}
                disabled={disabled}
                id='tags-outlined'
                options={[
                    { label: 'Select All', value: 'SELECT_ALL' },
                    ...options,
                ]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label ?? option}
                renderOption={(props, option, { selected }) => {
                    const selectAllProps =
                        option.value === 'SELECT_ALL' // To control the state of 'select-all' checkbox
                            ? { checked: allSelected }
                            : {};
                    return (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                {...selectAllProps}
                            />
                            {option.label}
                        </li>
                    );
                }}
                renderInput={(params) => {
                    return <TextField {...params} label={label} />;
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                }}
                onChange={handleChange}
                value={modifiedValue}
                // filterOptions={(values, a) => {
                //     console.log({ values, a });
                //     return [
                //         { label: 'Select All', value: 'SELECT_ALL' },
                //         ...values,
                //     ];
                // }}
            />
        );
    }
);
